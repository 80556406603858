.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 24px;
  width: 270px;
}

@media screen and (max-width: 480px) {
  .content {
    width: 100%;
  }
}

.oauth {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  &__title {
    font-size: 12px;
    color: var(--bzr-text-medium-grey);
    text-align: center;
    line-height: 19px;
  }

  &__items {
    display: flex;
    gap: 8px;

    button {
      all: unset;
      cursor: pointer;

      svg {
        color: #47c612;
      }

      svg path {
        fill: #fff;
      }
    }
  }

  &_not_available {
    .oauth__items {
      opacity: 0.2;
      pointer-events: none;
    }
  }
}

.title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 15px;

  h2 {
    color: var(--text-dark-black-primery, #2C2D31);
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }
}

.centeredText {
  text-align: center;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;

  &_column {
    flex-direction: column;
  }
}

.subTitle {
  color: var(--bzr-text-dark-black-secondary);
  text-align: center;
  font-size: 14px;
  margin-top: 14px;
  line-height: 145%;
}

.formItem {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__title {
    color: var(--bzr-text-medium-grey);
    font-size: 14px;
  }
}

.password {
  display: flex;
  flex-direction: column;
  gap: var(--bzr-spacing-xs);

  .passwordForgot {
    cursor: pointer;
    color: var(--bzr-main-green-default);
    margin-left: auto;
  }
}

.error {
  color: var(--bzr-secondary-error);
  margin-top: var(--bzr-spacing-xs);
}

.error_invisible {
  visibility: hidden;
}

.agreement {
  font-size: 12px;

  label {
    user-select: none;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  input {
    flex-shrink: 0;
  }

  a {
    font-size: 12px;
    color: #0085C5;
  }
}
.rememberMe {
  label {
    user-select: none;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.emailIcon {
  margin: 14px 0;
  width: 90px;
  height: 66px;
}

.toast {
  a {
    color: var(--bzr-main-blue-default);
  }
}
