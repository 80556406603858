@import '@/styles/variables/mixins/dotted';

@mixin font($size: 16px, $line-height: normal, $weight: 500, $color: var(--bzr-text-dark-black-primary)) {
  font-size: $size;
  line-height: $line-height;
  font-weight: $weight;
  color: $color;
}

@mixin flex-column($align: flex-start, $gap: var(--bzr-spacing-large)) {
  display: flex;
  flex-direction: column;
  align-items: $align;
  gap: $gap;
}

.email_align p {
  display: flex;
  align-items: center;
}

.contact_item,
.field {
  display: flex;
  gap: var(--bzr-spacing-xxl);
  width: 100%;
  &__create_page {
    justify-content: space-between;
    gap: unset;
  }
  .email_contact_wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-small);
    width: 67%;
    .tip {
      width: 100%;
      max-width: unset;
      @include font($size: 14px);
      line-height: 18.9px;
    }
  }
  p {
    width: 187px;
    @include font($color: var(--bzr-text-deep-medium-grey));
    @media (max-width: 700px) {
      width: 165px;
    }  
    .alert_svg {
      svg {
        margin-left: var(--bzr-spacing-xs);
        margin-top: 4px;
        fill: var(--bzr-main-blue-default);
        font-size: 24px;
      }
    }
  }  
  .input_view {
    display: flex;
    align-items: center;
    gap: var(--bzr-spacing-xs);
    width: 70%;
    height: 40px;
    padding: var(--bzr-spacing-small);
    background-color: var(--bzr-secondary-very-light-grey);
    border-radius: var(--bzr-radius-s);
    @include font($size: 14px, $color: var(--bzr-text-deep-medium-grey));
    svg {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      color: var(--bzr-text-medium-grey);
    }
    &_contacts {
      width: 310px;
    }
    &__create_page {
      width: 100%;
    }
    .email_value {
      max-width: 100%;
      @include bzr-dotted(1);
    }
  }
  .phone {
    background-color: var(--bzr-secondary-very-light-grey-2);
    color: var(--bzr-text-dark-black-primary);
    .edit_icon {
      flex: 1 1;
      height: 24px;
      text-align: right;
      svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
        color: var(--bzr-text-dark-black-primary);
      }
    }
  }
  .email_contact {
    display: flex;
    flex-direction: column;
    gap: var(--bzr-spacing-xs);
    .email_tips {
      display: flex;
      flex-direction: column;
      gap: var(--bzr-spacing-small);
      .error {
        width: 100%;
        font-size: 12px;
        color: var(--bzr-secondary-error);
      }
      button {
        width: fit-content;
        padding: 0;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
  .contact_verified {
    flex-direction: row;
    align-items: center;
    gap: var(--bzr-spacing-small);
    .check_svg {
      width: 24px;
      height: 24px;
      color: var(--bzr-main-green-default);
    }
    .info_svg {
      width: 24px;
      height: 24px;
      color: var(--bzr-main-blue-default);
    }
  }
  .description_text {
    gap: var(--bzr-spacing-xs);
    justify-content: space-between;
    align-items: flex-start;
    height: fit-content;
    width: 70%;
    p {
      width: 100%;
      font-size: 14px;
      color: var(--bzr-text-dark-black-primary);
    }
    svg {
      cursor: pointer;
      color: var(--bzr-text-dark-black-primary);
    }
  }
  .wrapper {
    @include flex-column($gap: var(--bzr-spacing-small));
    width: 70%;  
    p {
      width: 100%;
      @include font($size: 14px);
    }
    .change_tip {
      font-size: 13px;
      line-height: 18.85px;
      color: var(--bzr-text-medium-grey);
    }
    .error {
      @include font($size: 12px, $color: var(--bzr-secondary-error));
    }
  }  
  .add_contact {
    width: fit-content;
    padding: 0;
    @include font($size: 14px, $color: var(--bzr-main-blue-default));
    svg {
      width: 24px;
      height: 24px;
    }
  }
}
  
@media (max-width: 600px) {
  .contact_item,
  .field {
    flex-direction: column;
    gap: var(--bzr-spacing-small);
    width: 100%;
    .email_contact_wrapper {
      width: 100%;
    }
    .contact_header {
      display: none;
      &__create_page {
        display: block;
      }
    }
    .input_view {
      width: 100%;
    }
    .name {
      justify-content: space-between;
      background-color: var(--bzr-secondary-very-light-grey-2);
      color: var(--bzr-text-dark-black-primary);
      svg {
        color: var(--bzr-text-dark-black-primary);
      }
    }
    .field_header {
      display: none;
    }
    .description_text,
    .wrapper {
      width: 100%;
    }
  }
}

@media (max-width: 480px) {
  .contact_item {
    .contact_header__create_page {
      font-size: 16px;
      font-weight: 600;
      color: var(--bzr-text-dark-black-primary);
    }
    .contact_verified {
      position: relative;
      button {
        position: absolute;
        right: 6px;
      }
    }
  }
}
